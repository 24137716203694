import firebase from "firebase/app";
import "firebase/auth";

let firebaseConfig = {
  apiKey: "AIzaSyC5ZM5p4Gzr8M33L6SkPbM9Wxp9EjdHEj8",
  authDomain: "platzi-master-23c57.firebaseapp.com",
  databaseURL: "https://platzi-master-23c57.firebaseio.com",
  projectId: "platzi-master-23c57",
  storageBucket: "platzi-master-23c57.appspot.com",
  messagingSenderId: "913725937869",
  appId: "1:913725937869:web:a44143067e73c53c9b7184",
  measurementId: "G-TYK7DXPCN5",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
